import PropTypes from 'prop-types';

import withTheme from 'core/hocs/withTheme';
import withBreakpoint from 'core/hocs/withBreakpoint';
import { Mobile } from 'core/components/breakpoint';


import H4 from 'core/components/H4';
import Link from 'core/components/Link';
import Button from 'core/components/Button';
import AdWrapper from 'core/components/Ad/AdWrapper';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import { TopBanner } from 'site/components/Ads/mobile';
import { Indent } from 'core/components/Wrappers';
import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import NewsContent from './NewsContent';


function News({ isDesktop, theme, topics }) {
  if (!topics?.length) return null;

  const vertical = isDesktop ? VERTICAL_INDENT : VERTICAL_INDENT_MOBILE;

  return (
    <div className='news'>
      <style jsx>{`
        .control
          font 16px/20px ${theme.fonts.nuance}
      `}</style>
      <style jsx>{`
        .control
          text-align center
      `}</style>

      <H4>Новости</H4>
      <Indent top={isDesktop ? 15 : 20} bottom={isDesktop ? 30 : 20}>
        <NewsContent
          topics={topics}
          theme={theme}
          isDesktop={isDesktop}
        />
      </Indent>
      <div className='control' data-qa='control-button'>
        <Link to='/news' type='secondary'>
          <Button>Все новости</Button>
        </Link>
      </div>

      <Mobile>
        <AdWrapper top={vertical}>
          <TopBanner />
        </AdWrapper>
      </Mobile>
    </div>
  );
}

News.propTypes = {
  /** @ignore */
  isDesktop: PropTypes.bool,
  /** Список топиков */
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  /** @ignore */
  theme: PropTypes.object,
};

const NewsWithHOCs = withBreakpoint(withTheme(News));
NewsWithHOCs.displayName = 'News';

export default NewsWithHOCs;

export { News as StorybookComponent };
