import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import TopicHeaderTexts from 'core/components/TopicHeaderTexts';
import withBreakpoint from 'core/hocs/withBreakpoint';
import Socializator from 'core/components/Socializator';
import { Indent, TopicContentMaxWidth } from 'core/components/Wrappers';
import withTheme from 'core/hocs/withTheme';
import MarkdownWrapper from 'core/components/MarkdownWrapper';
import Divider from 'core/components/Divider';
import EmptyWrapper from 'core/components/EmptyWrapper';

import CoverBG from 'site/components/CoverBG';

import TopicCover from './covers';
import TopicTopline from 'core/components/TopicTopline';

const topicTypeMap = {
  news: { bgType: 0, withoutShares: false },
  article: { bgType: 1, withoutShares: false },
  gallery: { bgType: 3, withoutShares: true },
  video: { bgType: 0, withoutShares: false },
};


function TopicHeader(props) {
  const {
    content,
    isMobile,
    comments,
    isGameCompare,
    theme: {
      controls: {
        topicHeaderTexts: topicHeaderTextsAtoms,
      },
    },
  } = props;

  const {
    attributes: {
      topic_type: topicType,
      is_premium: isPremium,
      announce,
    },
  } = content;

  const { bgType, withoutShares } = topicTypeMap[topicType] || {};

  const Wrapper = isPremium && isMobile ? EmptyWrapper : TopicContentMaxWidth;

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .lead
          font ${topicHeaderTextsAtoms.lead.font}
          color ${topicHeaderTextsAtoms.lead.color}
          letter-spacing ${topicHeaderTextsAtoms.lead.letterSpacing || 0}
      `}</style>
    </scope>
  );

  return (
    <>
      {!isPremium && (
        <div data-qa='topic-header'>
          <TopicContentMaxWidth data-qa='topic-header-text'>
            <TopicTopline />
            <Indent bottom={isMobile ? 16 : 20} />
            <TopicHeaderTexts />
          </TopicContentMaxWidth>
        </div>
      )}

      <Indent bottom={24} />

      {isPremium && !!announce && (
        <Wrapper>
          <MarkdownWrapper inline className={scoped.wrapClassNames('lead')}>
            {announce}
          </MarkdownWrapper>
        </Wrapper>
      )}

      {!comments && (
        <CoverBG type={bgType} hidePaddingBottom>
          {!isPremium && <TopicCover content={content} />}
          <TopicContentMaxWidth>
            {(!withoutShares && !isGameCompare) && (
              <Indent top={isMobile ? 0 : 20} bottom={isMobile ? 0 : 20}>
                <Socializator />
              </Indent>
            )}
          </TopicContentMaxWidth>
        </CoverBG>
      )}
      {(isPremium && !isMobile) && (
        <TopicContentMaxWidth>
          <Indent top={16} />
          <Divider />
        </TopicContentMaxWidth>
      )}
      <scoped.styles />
    </>
  );
}

TopicHeader.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
  isMobile: PropTypes.bool,
  comments: PropTypes.bool,
  isGameCompare: PropTypes.bool,
  theme: PropTypes.object,
};

export default withBreakpoint(withTheme(TopicHeader));
