import PropTypes from 'prop-types';
import { get } from 'core/libs/lodash';

import resolveRelationships from 'core/utils/relationships';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import withCard from 'core/hocs/withCard';

import Link from 'core/components/Link';
import Button from 'core/components/Button';
import Image from 'core/components/Image';

import { buildRubricUrl } from 'site/utils';

import mapTypes from './types';
import mapSizes from './sizes';

import styles from './index.styl';

const requiredPayloadImports = [
  'image',
  'rubric',
  'tags',
];

const requiredPayloadFields = [
  'headline',
  'list_headline',
  'alternative_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  rubric: [],
  tags: [],
});

function CardSelection(props) {
  const {
    content,
    theme,
    type,
  } = props;

  if (!content) return null;

  const {
    image: {
      versions: {
        original: originalCover,
        thumbnail: previewCover,
      },
    },
    rubric: {
      title: rubricTitle,
      slug: rubricSlug,
      root_slug: rubricRoot,
    },
    tags,
  } = relationships(content);

  const selectionTag = get(tags, '[0].attributes.slug');

  const {
    size,
  } = mapTypes[type] || {};

  const {
    headlineSize,
    altHeadlineSize,
    width: imageWidth,
    height: imageHeight,
    padding: contentPadding,
  } = mapSizes[size] || {};

  const {
    headline,
    list_headline: listHeadline,
    alternative_headline: altHeadline,
  } = content.attributes;

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .rubricLink
          font 700 18px/1.2 ${theme.fonts.nuance}
          color ${theme.colors.primary}

          &:visited
            color ${theme.colors.primary}

          &:hover
            color ${theme.colors.content}
      `}</style>
    </scope>
  );

  return (
    <div className={styles.cardSelection}>
      <style jsx>{`
        .${styles.rubricText}
          padding ${contentPadding}

        .${styles.headline}
          font 700 ${headlineSize} ${theme.fonts.nuance}
          color ${theme.colors.primary}

        .${styles.altHeadline}
          font ${altHeadlineSize} ${theme.fonts.nuance}
          color ${theme.colors.primary}

        .${styles.overlay}
          background ${theme.colors.content}
          transition opacity ${theme.animations.hover}
      `}</style>
      <div className={styles.image}>
        <div className={styles.rubric}>
          <div className={styles.rubricText}>
            <Link
              className={scoped.wrapClassNames('rubricLink')}
              to={buildRubricUrl(rubricSlug, rubricRoot)}
            >
              {rubricTitle + ' /'}
            </Link>
          </div>
        </div>
        <Image
          {...originalCover && { src: originalCover }}
          previewSrc={previewCover}
          aspectRatio={+(imageWidth / imageHeight).toFixed(2)}
        />
        <div className={styles.overlay} />
      </div>
      <div className={styles.content}>
        <div className={styles.headline}>{listHeadline || headline}</div>
        {altHeadline && <div className={styles.altHeadline}>{altHeadline}</div>}
        {selectionTag && (
          <Link
            to={`/label/${selectionTag}`}
            type='secondary'
            className={styles.link}
          >
            <Button>Смотреть</Button>
          </Link>
        )}
      </div>
      <scoped.styles />
    </div>
  );
}

CardSelection.propTypes = {
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1, 2]),
  /** Контент топика */
  content: PropTypes.object,
  /** @ignore */
  theme: PropTypes.object,
};

CardSelection.defaultProps = {
  type: 0,
};

const Card = withCard(CardSelection);

Card.displayName = 'CardSelection';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { CardSelection as StorybookComponent };
