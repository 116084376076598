import PropTypes from 'prop-types';
import cx from 'classnames';

import resolveRelationships from 'core/utils/relationships';

import withTheme from 'core/hocs/withTheme';

import Link from 'core/components/Link';
import Image from 'core/components/Image';

import { cardDateFormat } from 'site/utils';
import { DEFAULT_CARD_COVER_SQUARE } from 'site/constants';

import mapTypes from './types';
import mapSizes from './sizes';

import styles from './index.styl';

const requiredPayloadImports = [
  'image',
  'rubric',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'alternative_headline',
  'published_at',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  rubric: [],
});


function Card3(props) {
  const {
    content,
    theme,
    type,
    utm,
  } = props;

  const {
    size,
    withRubric,
    withBackground,
    withDate,
    rowReverse,
    hideImage,
    hideAltHeadline,
  } = mapTypes[type] || {};

  const {
    headlineSize,
    altHeadlineSize,
    width: imageWidth,
    height: imageHeight,
  } = mapSizes[size] || {};

  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
    alternative_headline: altHeadline,
    published_at: publishedAt,
  } = content.attributes;

  const {
    remote_image: remoteImage,
  } = content.extra || {};

  const {
    image: {
      versions: {
        original: originalCover,
        thumbnail: previewCover,
      },
    },
    rubric: {
      root_title: rubricRoot,
    },
  } = relationships(content);

  const defaultCover = !originalCover && !previewCover;
  const to = utm ? link + utm : link;

  return (
    <div className={cx(
      'card3',
      rowReverse && styles._rowReverse,
      withBackground && styles._withBackground,
      defaultCover && styles._defaultCover,
    )}
    >
      <style jsx>{`
        .${styles.headline}
          color ${theme.colors.primary}
          font ${headlineSize} ${theme.fonts.nuance}
          transition color ${theme.animations.hover}

        .${styles.altHeadline}
          color ${theme.colors.primary}
          font ${altHeadlineSize} ${theme.fonts.nuance}

        .${styles.image}
          height ${imageHeight}px
          flex 0 0 ${imageWidth}px

        .${styles.overlay}
          background ${theme.colors.primary}
          transition opacity ${theme.animations.hover}

        .${styles.date}
        .${styles.rubric}
          font 14px/18px ${theme.fonts.nuance}
          color ${theme.colors.hint}

        .${styles.rubric}
          &:before
            background ${theme.colors.hint}

        .card3
          &.${styles._withBackground}
            background ${theme.colors.content}
          &:hover
            .${styles.headline}
              color ${theme.colors.active1}
      `}</style>
      <Link
        to={to}
        type='secondary'
        className={styles.link}
      >
        <div className={styles.body}>
          <div className={styles.headline}>{listHeadline || headline}</div>
          {!hideAltHeadline && <div className={styles.altHeadline}>{altHeadline}</div>}
          <div className={styles.meta}>
            {withDate &&
              <div className={styles.date}>
                {cardDateFormat(publishedAt, { forceDisableYear: true })}
              </div>
            }
            {withRubric && <div className={styles.rubric}>{rubricRoot}</div>}
          </div>
        </div>
        {!hideImage && (
          <div className={styles.image}>
            <Image
              {...remoteImage
                ? { url: remoteImage }
                : { src: originalCover || DEFAULT_CARD_COVER_SQUARE }}
              previewSrc={previewCover}
              aspectRatio={1}
              maxWidth={imageWidth}
            />
            <div className={styles.overlay} />
          </div>
        )}
      </Link>
    </div>
  );
}

Card3.propTypes = {
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8]),
  /** Контент топика */
  content: PropTypes.object,
  /** @ignore */
  theme: PropTypes.object,
  /** Набор utm-меток, добавляемых в ссылку */
  utm: PropTypes.string,
};

Card3.defaultProps = {
  type: 1,
};

const Card = withTheme(Card3);

Card.displayName = 'Card3';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { Card3 as StorybookComponent };
