import { Fragment } from 'react';
import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { topicQuery } from 'core/queries/topic';
import Topic from 'core/components/Topic';
import InfiniteTopic from 'core/components/InfiniteTopic';
import { Mobile } from 'core/components/breakpoint';
import withBreakpoint from 'core/hocs/withBreakpoint';
import { resolve } from 'core/utils/env';

import GameCompare from 'core/components/GameCompare';
import { SideIndent, Indent } from 'core/components/Wrappers';
import FooterAds from 'site/components/Ads/FooterAds';
import { TopBanner } from 'site/components/Ads/mobile';
import Boroda from 'site/components/Boroda';
import { checkScrollToSiteInTopic } from 'core/components/ScrollToSite';
import PremiumTopicHeader from 'core/components/PremiumTopicHeader';
import PageLoader from 'core/components/Loader/PageLoader';

import { isGameCompare } from 'core/components/GameCompare/utils';

import TopicWrapper from './TopicWrapper';
import TopicHeader from './TopicHeader';
import TopicContent from './TopicContent';
import TopicFooter from './TopicFooter';


function topicRenderer(topic, enhancedTopic, location, isDesktop) {
  const {
    content,
    infinityIndex,
  } = enhancedTopic;

  const { attributes: { is_premium: isPremium } } = content;
  const isScrollToSite = checkScrollToSiteInTopic(enhancedTopic);
  const isGameComparePage = isGameCompare(location.pathname);
  const isPremiumInInfinity = isPremium && infinityIndex !== 0;

  return (
    <Fragment>
      {!isPremium &&
        <Mobile>
          <TopBanner lazy />
        </Mobile>
      }
      {isPremiumInInfinity && (
        <Indent top={20} left={isDesktop ? 0 : 20}>
          <PremiumTopicHeader
            content={content}
            isPremiumInInfinity
          />
        </Indent>
      )}
      <TopicWrapper content={content} infinityIndex={infinityIndex}>
        <TopicHeader content={content} isGameCompare={isGameComparePage} />
        {!isGameComparePage && (
          <Fragment>
            <TopicContent content={content} />
            {!isPremium && (
              <TopicFooter
                content={content}
                hideSuperFooter={isScrollToSite}
                infinityIndex={infinityIndex}
              />
            )}
          </Fragment>
        )}

        {isGameComparePage && (
          <GameCompare content={content} />
        )}

        {!isScrollToSite && !isGameComparePage && <Boroda />}
      </TopicWrapper>
    </Fragment>
  );
}

function TopicPage(props) {
  const {
    isDesktop,
    location,
  } = props;
  const isGameComparePage = isGameCompare(location.pathname);

  const { data, isLoading: topicIsLoading } = useRequest(topicQuery(props));

  if (topicIsLoading) return <PageLoader />;

  if (isGameComparePage) {
    return (
      <Topic content={data}>
        {(e, t) => topicRenderer(e, t, location)}
      </Topic>
    );
  }
  return (
    <Fragment>
      <InfiniteTopic
        content={data}
        rcmBlockId={resolve({
          '*': '5250ff96824f4b14b5e46d31a813482c',
          'production': isDesktop ? 'd54573d674584c75919ba011f073b15f' : 'c100262c65664e89bcda37a2ef5f5ab1',
        })}
      >
        {(t, e) => topicRenderer(t, e, location, isDesktop)}
      </InfiniteTopic>
      <SideIndent>
        <FooterAds contextCount={2} />
      </SideIndent>
    </Fragment>
  );
}

TopicPage.propTypes = {
  isDesktop: PropTypes.bool,
  location: PropTypes.object,
};

export default withBreakpoint(TopicPage);
