import { Fragment } from 'react';
import PropTypes from 'prop-types';

import withBindProps from 'core/hocs/withBindProps';
import withBreakpoint from 'core/hocs/withBreakpoint';

import Feed from 'core/components/Feed';
import withReplaceCardWithAd from 'core/hocs/withReplaceCardWithAd';

import { Indent } from 'core/components/Wrappers';

import Card3 from 'site/cards/Card3';
import Card2 from 'site/cards/Card2';
import CardMain from 'site/cards/CardMain';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import { Native2AsCard2Type3 } from 'site/components/Ads/desktop';


const Card2Type3 = withBindProps({ type: 3 })(Card2);
const Card3Type2 = withBindProps({ type: 2 })(Card3);
const CardMainType4 = withBindProps({ type: 4 })(CardMain);

function MainListPart3({ isDesktop, topics }) {
  const vertical = isDesktop ? VERTICAL_INDENT : VERTICAL_INDENT_MOBILE;

  const first = topics.slice(0, 2);
  const second = topics.slice(2, 5);

  return (
    <Fragment>
      {first.length > 0 && (
        <Indent bottom={vertical}>
          <Feed
            content={first}
            card={Card2Type3}
            grid
            columns={2}
            interitemSpacing={vertical}
            cardsReplacement={{
              1: withReplaceCardWithAd(Native2AsCard2Type3, isDesktop),
            }}
          />
        </Indent>
      )}
      {second.length > 0 && (
        <Feed
          content={second}
          card={isDesktop ? CardMainType4 : Card3Type2}
          grid
          columns={3}
          interitemSpacing={vertical}
        />
      )}
    </Fragment>
  );
}

MainListPart3.propTypes = {
  isDesktop: PropTypes.bool,
  topics: PropTypes.array,
};

export default withBreakpoint(MainListPart3);
