import PropTypes from 'prop-types';

import withBreakpoint from 'core/hocs/withBreakpoint';
import withTheme from 'core/hocs/withTheme';

import MobileLogo from 'site/icons/MobileLogo';
import DesktopLogo from 'site/icons/Logo';

import mapTypes from './types';


function Logo(props) {
  const {
    type,
    isMobile,
    theme: {
      colors,
    },
  } = props;

  const {
    width,
  } = mapTypes[type] || {};

  if (isMobile && type !== 1) return <MobileLogo fill={colors?.logo} />;

  return (
    <DesktopLogo width={width} fill={colors?.logo} />
  );
}

Logo.propTypes = {
  isMobile: PropTypes.bool,
  type: PropTypes.oneOf([0, 1]).isRequired,
  theme: PropTypes.object,
};

Logo.defaultProps = {
  type: 0,
};

export default withBreakpoint(withTheme(Logo));
