import { Fragment } from 'react';
import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { rawAuthorsQuery } from 'core/queries/authorsPage';

import H1 from 'core/components/H1';
import AuthorsPage from 'core/components/AuthorsPage';
import AdWrapper from 'core/components/Ad/AdWrapper';
import { Indent, SideIndent } from 'core/components/Wrappers';

import { Mobile } from 'core/components/breakpoint';
import withBreakpoint from 'core/hocs/withBreakpoint';

import PageLayout from 'site/components/PageLayout';

import FooterAds from 'site/components/Ads/FooterAds';
import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import { TopBanner } from 'site/components/Ads/mobile';


function Authors(props) {
  const { isMobile } = props;
  const { data: rawAuthors, isLoading } = useRequest(rawAuthorsQuery(props));
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <Fragment>
      <PageLayout>
        <Mobile>
          <AdWrapper bottom={vertical}>
            <TopBanner />
          </AdWrapper>
        </Mobile>
        <H1>Авторы</H1>
        <Indent bottom={vertical} />
        <AuthorsPage rawAuthors={rawAuthors} isLoading={isLoading} />
      </PageLayout>
      <SideIndent>
        <FooterAds contextCount={2} />
      </SideIndent>
    </Fragment>
  );
}

Authors.propTypes = {
  isMobile: PropTypes.bool,
};


export default withBreakpoint(Authors);
