import PropTypes from 'prop-types';

import AdWrapper from 'core/components/Ad/AdWrapper';
import withBreakpoint from 'core/hocs/withBreakpoint';

import { Mobile, Desktop } from 'core/components/breakpoint';

import { Context } from 'site/components/Ads/desktop';
import { Footer as FootBanner } from 'site/components/Ads/mobile';

import {
  VERTICAL_INDENT_MOBILE,
  VERTICAL_INDENT_ADV_TOP,
  VERTICAL_INDENT_ADV_BOTTOM,
} from 'site/constants';

function FooterAds({ contextCount, isMobile }) {
  const verticalAdvBottom = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT_ADV_TOP;
  const verticalAdvTop = isMobile ? 0 : VERTICAL_INDENT_ADV_BOTTOM;
  return (
    <AdWrapper
      top={verticalAdvTop}
      bottom={verticalAdvBottom}
    >
      <Desktop>
        <Context count={contextCount} />
      </Desktop>
      <Mobile>
        <FootBanner />
      </Mobile>
    </AdWrapper>
  );
}

FooterAds.propTypes = {
  contextCount: PropTypes.number,
  isMobile: PropTypes.bool.isRequired,
};

FooterAds.defaultProps = {
  contextCount: 1,
};

export default withBreakpoint(FooterAds);
