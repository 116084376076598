import { Fragment } from 'react';
import PropTypes from 'prop-types';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import withBreakpoint from 'core/hocs/withBreakpoint';
import withBindProps from 'core/hocs/withBindProps';
import { Desktop, Mobile } from 'core/components/breakpoint';
import withReplaceCardWithAd from 'core/hocs/withReplaceCardWithAd';

import Feed from 'core/components/Feed';
import AdWrapper from 'core/components/Ad/AdWrapper';
import PageLoader from 'core/components/Loader/PageLoader';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import { Indent, NegativeMobile } from 'core/components/Wrappers';
import CoverBG from 'site/components/CoverBG';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import {
  SuperFooter,
  Context,
  Native1AsCard2Type4,
  Native2AsCard2Type3,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  Listing2,
  ListingSpec,
} from 'site/components/Ads/mobile';

import cardsTypes from './cards';

import Card4 from 'site/cards/Card4';


const Card4Type3 = withBindProps({ type: 3 })(Card4);
const Card4Type4 = withBindProps({ type: 4 })(Card4);

function Content(props) {
  const {
    topics,
    isLoading,
    cardsForRubric,
    isDesktop,
    breakpoint,
  } = props;

  if (isLoading) return <PageLoader />;
  if (!topics?.length) return null;

  const first = topics[0];
  const second = topics.slice(1, 3);
  const third = topics.slice(3, 6);
  const fourth = topics.slice(6, 8);
  const fifth = topics.slice(8, 11);
  const sixth = topics.slice(11, 13);
  const seventh = topics.slice(13);

  const vertical = isDesktop ? VERTICAL_INDENT : VERTICAL_INDENT_MOBILE;

  const feedProps = {
    grid: true,
    interitemSpacing: isDesktop ? VERTICAL_INDENT : VERTICAL_INDENT_MOBILE,
  };

  const forRubric = cardsForRubric ? 'rubric' : null;
  const CardSm = (cardsTypes[breakpoint].sm[forRubric] || cardsTypes[breakpoint].sm).Card;
  const CardMd = (cardsTypes[breakpoint].md[forRubric] || cardsTypes[breakpoint].md).Card;

  return (
    <Indent top={vertical} bottom={vertical}>
      <AdWrapper top={vertical}>
        <CoverBG type={1}>
          {first &&
            <Indent bottom={vertical} data-qa='content-first'>
              <Desktop>
                <Card4Type4 content={first} />
              </Desktop>
              <Mobile>
                <NegativeMobile>
                  <Card4Type3 content={first} />
                </NegativeMobile>
              </Mobile>
            </Indent>
          }

          {second.length > 0 && (
            <Fragment>
              <Feed
                columns={2}
                card={CardMd}
                content={second}
                dataQa='content-second'
                {...feedProps}
              />
              <Desktop>
                <SuperFooter />
              </Desktop>
              <Mobile>
                <Listing1 />
              </Mobile>
            </Fragment>
          )}
        </CoverBG>

        {third.length > 0 && (
          <Indent bottom={vertical}>
            <CoverBG type={2}>
              <AdWrapper top={0}>
                <Feed
                  columns={3}
                  card={CardSm}
                  content={third}
                  cardsReplacement={{
                    1: withReplaceCardWithAd(Native1AsCard2Type4, isDesktop),
                  }}
                  dataQa='content-third'
                  {...feedProps}
                />
              </AdWrapper>
            </CoverBG>
          </Indent>
        )}

        {fourth.length > 0 && (
          <Indent bottom={vertical}>
            <Feed
              columns={2}
              card={CardMd}
              content={fourth}
              dataQa='content-fourth'
              {...feedProps}
            />
          </Indent>
        )}

        {fifth.length > 0 && (
          <Indent bottom={vertical}>
            <Feed
              columns={3}
              card={CardSm}
              content={fifth}
              dataQa='content-fifth'
              {...feedProps}
            />
            <Desktop><Context /></Desktop>
            <Mobile><ListingSpec /></Mobile>
          </Indent>
        )}

        {sixth.length > 0 && (
          <Indent bottom={vertical}>
            <AdWrapper top={0}>
              <Feed
                columns={2}
                card={CardMd}
                content={sixth}
                dataQa='content-sixth'
                {...feedProps}
                cardsReplacement={{
                  1: withReplaceCardWithAd(Native2AsCard2Type3, isDesktop),
                }}
              />
            </AdWrapper>
            <Mobile><Listing2 /></Mobile>
          </Indent>
        )}

        {seventh.length > 0 && (
          <Indent bottom={vertical}>
            <Feed
              columns={3}
              card={CardSm}
              content={seventh}
              dataQa='content-seventh'
              {...feedProps}
            />
          </Indent>
        )}
      </AdWrapper>
    </Indent>
  );
}

Content.propTypes = {
  /**
   * Параметр отвечающий за отрисовку карточек без рубрики
   */
  cardsForRubric: PropTypes.bool,
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  isLoading: PropTypes.bool,
  isDesktop: PropTypes.bool,
  breakpoint: breakpointPropTypes(),
};

export default withBreakpoint(Content);
