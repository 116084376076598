import PropTypes from 'prop-types';
import cx from 'classnames';
import { withRouter } from 'core/libs/router';

import resolveRelationships from 'core/utils/relationships';

import withCard from 'core/hocs/withCard';

import Link from 'core/components/Link';
import Image from 'core/components/Image';
import AdvTooltip from 'core/components/AdvTooltip';

import { cardDateFormat } from 'site/utils';

import mapTypes from './types';
import mapSizes from './sizes';

import styles from './index.styl';

import { DEFAULT_CARD_COVER, LAYOUT_MAX_WIDTH_MOBILE } from 'site/constants';

const requiredPayloadImports = [
  'image',
  'rubric',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'alternative_headline',
  'published_at',
  'ad_label',
  'advertiser_name',
  'link_with_announce_erid',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  rubric: [],
});


function Card2(props) {
  const {
    content,
    theme,
    isMobile,
    type,
    utm,
    location,
    position,
  } = props;

  const {
    size,
    withRubricOnImage,
    withRubricBottom,
    withBackground,
    withoutDate,
    withoutBorderRadius,
    withoutDefaultCover,
    hideImage,
  } = mapTypes[type] || {};

  const {
    headlineSize,
    altHeadlineSize,
    width: imageWidth,
    height: imageHeight,
    bgThemeColor,
  } = mapSizes[size] || {};

  if (!content) return null;

  const {
    image: {
      versions: {
        original: originalCover,
        thumbnail: previewCover,
      },
    },
    rubric: {
      root_title: rubricRoot,
    },
  } = relationships(content);

  const {
    link,
    headline,
    list_headline: listHeadline,
    alternative_headline: altHeadline,
    published_at: publishedAt,
    ad_label: adLabel,
    link_with_announce_erid: linkWithAnnounceErid,
    advertiser_name: advertiserName,
  } = content.attributes;

  const {
    link_attrs: linkAttrs,
    remote_image: remoteImage,
  } = content.extra || {};

  const isMainPage = location.pathname === '/';
  const hasCover = originalCover || previewCover;
  const imageRatio = +(imageWidth / imageHeight).toFixed(2);
  const shouldShowAdvTooltip = !!(isMainPage && position === 1 && linkWithAnnounceErid && advertiserName && adLabel);
  const topicLink = shouldShowAdvTooltip ? linkWithAnnounceErid : link;
  const to = utm ? topicLink + utm : topicLink;

  return (
    <div data-qa='card2' className={cx(
      'card2',
      withBackground && styles._withBackground,
      !(hideImage || hasCover || withoutDefaultCover) && styles._defaultCover,
    )}
    >
      <style jsx>{`
        .${styles.content}
          background ${withBackground ? theme.colors[bgThemeColor] : 'transparent'}

        .${styles.headline}
          font 700 ${headlineSize} ${theme.fonts.nuance}
          color ${theme.colors.primary}
          transition color ${theme.animations.hover}

        .${styles.altHeadline}
          font ${altHeadlineSize} ${theme.fonts.nuance}
          color ${theme.colors.primary}

        .${styles.rubric}
          max-width calc(100% / ${imageRatio} + 5px)

        .${styles.rubricText}
          font 700 10px/1.2 ${theme.fonts.nuance}
          color ${theme.colors.content}
          background ${theme.colors.hint}
          transition background ${theme.animations.hover}

        .${styles.overlay}
          background ${theme.colors.primary}
          transition opacity ${theme.animations.hover}

        .${styles.metaItem}
          font 14px/18px ${theme.fonts.nuance}
          color ${theme.colors.hint}

          & + &:before
            background ${theme.colors.hint}

        .card2
          position relative

          &:hover
            .${styles.headline}
              color ${theme.colors.active1}
            .${styles.rubricText}
              background ${theme.colors.active1}

        .${styles.image}
          border-radius ${withoutBorderRadius ? 0 : '5px 5px 0 0'}
      `}</style>
      <Link
        to={to}
        type='secondary'
        className={styles.link}
        {...linkAttrs && {
          innerRef: node => {
            if (node) {
              linkAttrs.forEach(({ name, value }) => node.setAttribute(name, value));
            }
          },
        }}
      >
        {!hideImage && (hasCover || !withoutDefaultCover) && (
          <div className={styles.image}>
            <Image
              {...remoteImage
                ? { url: remoteImage }
                : { src: originalCover || DEFAULT_CARD_COVER }}
              previewSrc={previewCover}
              aspectRatio={imageRatio}
              {...imageHeight && { height: imageHeight }}
              maxWidth={isMobile ? LAYOUT_MAX_WIDTH_MOBILE : imageWidth}
            />
            <div className={styles.overlay} />
            {withRubricOnImage && (
              <div className={styles.rubric} data-qa='card2-rubric'>
                <div className={styles.rubricText}>{rubricRoot}</div>
              </div>
            )}
          </div>
        )}
        <div className={styles.content} data-qa='card2-content'>
          <div className={styles.headline} data-qa='card2-headline'>{listHeadline || headline}</div>
          <div className={styles.altHeadline} data-qa='card2-alt-headline'>{altHeadline}</div>
          <div className={styles.meta}>
            {!withoutDate &&
              <div className={styles.metaItem}>
                {cardDateFormat(publishedAt, { forceDisableYear: true })}
              </div>
            }
            {withRubricBottom && rubricRoot &&
              <div className={styles.metaItem}>{rubricRoot}</div>
            }
          </div>
        </div>
      </Link>
      {shouldShowAdvTooltip && (
        <AdvTooltip
          text={advertiserName}
          adLabel={adLabel}
        />
      )}
    </div>
  );
}

Card2.propTypes = {
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),
  /** Контент топика */
  content: PropTypes.object,
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  theme: PropTypes.object,
  /** utm-метки для ссылки */
  utm: PropTypes.string,
  /** @ignore */
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  position: PropTypes.number,
};

Card2.defaultProps = {
  type: 0,
};

const Card = withCard(withRouter(Card2));

Card.displayName = 'Card2';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { Card2 as StorybookComponent };
