import { Fragment } from 'react';
import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import StickyPortal from 'core/components/StickyPortal';
import { Desktop, Mobile } from 'core/components/breakpoint';
import withBreakpoint from 'core/hocs/withBreakpoint';

import Bouesque from 'core/components/Bouesque';
import AdWrapper from 'core/components/Ad/AdWrapper';
import ColumnLayout from 'core/components/ColumnLayout';

import {
  Ad240x400,
  Ad240x400Second,
  Parallax,
} from 'site/components/Ads/desktop';

import {
  ListingSpec,
} from 'site/components/Ads/mobile';

import { Indent } from 'core/components/Wrappers';
import News from 'site/components/News';
import Popular from 'site/components/Popular';
import CoverBG from 'site/components/CoverBG';

import {
  SIDE_INDENT,
  SIDE_INDENT_MOBILE,
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
  VERTICAL_INDENT_ADV_TOP,
  VERTICAL_INDENT_ADV_BOTTOM,
} from 'site/constants';

import SelfPromo from 'core/components/SelfPromo';
import { Smi2Vertical } from 'site/components/Partners';

import MainListPart1 from './MainListPart1';
import MainListPart2 from './MainListPart2';
import MainListPart3 from './MainListPart3';
import Selection from './Selection';


function MainPage(props) {
  const {
    topics,
    popular,
    selection,
    news,
    isMobile,
    isDesktop,
  } = props;

  const horisontal = isMobile ? SIDE_INDENT_MOBILE : SIDE_INDENT;
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const verticalAdvTop = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT_ADV_TOP;
  const verticalAdvBottom = isMobile ? 0 : VERTICAL_INDENT_ADV_BOTTOM;

  const first = topics.slice(0, 6);
  const second = topics.slice(6, 10);
  const third = topics.slice(10, 15);

  return (
    <Fragment>
      <ColumnLayout
        rightColumn={(
          <Fragment>
            <Bouesque />
            <Indent bottom={vertical} />
            <StickyPortal listenStickyRemoveEvent>
              <AdWrapper bottom={vertical}>
                <Ad240x400 />
                <SelfPromo />
                <Smi2Vertical />
                <Indent top={vertical} />
                <Ad240x400Second />
              </AdWrapper>
            </StickyPortal>
          </Fragment>
        )}
      >
        <AdWrapper bottom={vertical}>
          <News topics={news} />
          {first.length > 0 && (
            <Indent top={20}>
              <MainListPart1 topics={first} />
            </Indent>
          )}
          {second.length > 0 && (
            <Fragment>
              <Desktop><Indent top={vertical} /></Desktop>
              <MainListPart2 topics={second} />
            </Fragment>
          )}
        </AdWrapper>
      </ColumnLayout>
      <Desktop>
        <AdWrapper
          left={-horisontal}
          right={-horisontal}
          top={verticalAdvTop}
          bottom={verticalAdvBottom}
        >
          <Parallax />
        </AdWrapper>
      </Desktop>
      <ColumnLayout
        rightColumn={(
          <Fragment>
            <Indent top={vertical} />
            <StickyPortal>
              <Popular popular={popular} />
            </StickyPortal>
          </Fragment>
        )}
      >
        <Mobile>
          <Indent top={vertical} />
          <CoverBG type={1} hidePaddingBottom>
            <ListingSpec />
          </CoverBG>
        </Mobile>
        <AdWrapper bottom={vertical}>
          <Desktop>
            <Indent top={vertical} />
          </Desktop>
          <Selection content={selection} isDesktop={isDesktop} />
          {third.length > 0 && (
            <Indent top={vertical}>
              <MainListPart3 topics={third} />
            </Indent>
          )}
        </AdWrapper>
      </ColumnLayout>
    </Fragment>
  );
}

MainPage.propTypes = {
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)).isRequired,
  popular: PropTypes.arrayOf(modelPropTypes(topicAttributes)).isRequired,
  selection: PropTypes.object,
  news: PropTypes.array,
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(MainPage);
