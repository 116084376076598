import PropTypes from 'prop-types';

import color from 'core/libs/color';

import resolveRelationships from 'core/utils/relationships';

import withTheme from 'core/hocs/withTheme';

import Link from 'core/components/Link';
import Image from 'core/components/Image';

import mapTypes from 'site/cards/Card2/types';
import mapSizes from 'site/cards/Card2/sizes';

import styles from './index.styl';

const requiredPayloadImports = [
  'image',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'alternative_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});


function Card5(props) {
  const {
    content,
    theme,
    type,
  } = props;

  const { size } = mapTypes[type] || {};

  const {
    width: imageWidth,
    height: imageHeight,
  } = mapSizes[size] || {};

  if (!content) return null;

  const {
    image: {
      versions: {
        original: originalCover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content);

  const {
    link,
    headline,
    list_headline: listHeadline,
    alternative_headline: altHeadline,
  } = content.attributes;

  return (
    <div className={styles.card5}>
      <style jsx>{`
        .${styles.card5}
          background ${theme.colors.active1}

        .${styles.headline}
          font 700 20px/24px ${theme.fonts.nuance}
          color ${theme.colors.content}

        .${styles.altHeadline}
          font 16px/22px ${theme.fonts.nuance}
          color ${theme.colors.content}
          transition opacity ${theme.animations.hover}

        .${styles.overlay}
          background linear-gradient(180deg,
            ${color(theme.colors.active1).alpha(0)} 0,
            ${color(theme.colors.active1).alpha(0.3)} 40%,
            ${color(theme.colors.active1).alpha(1)} 90%
          )
      `}</style>
      <Link
        to={link}
        type='secondary'
        className={styles.link}
      >
        <div className={styles.image}>
          {originalCover && <Image
            {...originalCover && { src: originalCover }}
            previewSrc={previewCover}
            aspectRatio={+(imageWidth / imageHeight).toFixed(2)}
            maxWidth={imageWidth}
          />}
          <div className={styles.overlay} />
        </div>
        <div className={styles.content}>
          <div className={styles.headline}>{listHeadline || headline}</div>
          <div className={styles.altHeadline}>{altHeadline}</div>
        </div>
      </Link>
    </div>
  );
}

Card5.propTypes = {
  /** Тип карточки */
  type: PropTypes.number,
  /** Контент топика */
  content: PropTypes.object,
  /** @ignore */
  theme: PropTypes.object,
};

Card5.defaultProps = {
  type: 0,
};

const Card = withTheme(Card5);

Card.displayName = 'Card5';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { Card5 as StorybookComponent };
