import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { filterRequiredParams } from 'core/utils/api';

import PopularCore from 'core/components/Popular';
import withBindProps from 'core/hocs/withBindProps';

import FeedHeader from 'site/components/FeedHeader';
import { PartnerWrapper } from 'site/components/Wrappers';

import Card3 from 'site/cards/Card3';


const include = filterRequiredParams([Card3], 'include');
const fields = filterRequiredParams([Card3], 'fields');

const HeaderComponent = withBindProps({ type: 2 })(FeedHeader);
const FeedCard = withBindProps({ type: 5 })(Card3);

export default function Popular({ popular }) {
  return (
    <PartnerWrapper>
      <PopularCore
        title='Самое читаемое'
        headerComponent={HeaderComponent}
        card={FeedCard}
        interitemSpacing={20}
        limit={5}
        sort='-views_count'
        include={include}
        fields={fields}
        topics={popular}
      />
    </PartnerWrapper>
  );
}

Popular.propTypes = {
  /** Контент блока, соответствующий модели `topicAttributes` */
  popular: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
};
