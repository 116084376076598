import PropTypes from 'prop-types';

import withTheme from 'core/hocs/withTheme';

import mapSizes from 'site/cards/Card2/sizes';
import mapTypes from 'site/cards/Card2/types';
import Card2Styles from './index';

const CARD_TYPE = 4;

const NativeStyles = ({ theme }) => {
  const { [mapTypes[CARD_TYPE].size]: cardSizes } = mapSizes;

  const {
    height: imageHeight,
    width: imageWidth,
    headlineSize,
    altHeadlineSize,
  } = cardSizes;

  const IMAGE_RATIO = imageWidth / imageHeight;

  return (
    <style jsx global>{`
      .native-ad-1
        .ad_native_img
          max-width ${Math.floor(imageHeight * IMAGE_RATIO)}px

        .ad_native_title
          font 700 ${headlineSize} ${theme.fonts.nuance}

        .ad_native_desc
          font ${altHeadlineSize} ${theme.fonts.nuance}
    `}</style>
  );
};

NativeStyles.propTypes = {
  theme: PropTypes.object,
};

export default function Native1Styles(Component) {
  return withTheme(props => (
    <div className='native-ad native-ad-1'>
      <NativeStyles {...props} />
      <Card2Styles {...props} />
      <Component {...props} />
    </div>
  ));
}
