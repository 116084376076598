import PropTypes from 'prop-types';

import withBreakpoint from 'core/hocs/withBreakpoint';

import RecommendationWidget from 'core/components/RecommendationWidget';


function SiteRecommender({ isMobile }) {
  return (
    <RecommendationWidget
      blockId={isMobile ? '7f8306243b23464986d7de48723eb382' : '667cd9dd4bd5410a9b4d0fc8719bd6cd'}
      title='Популярное'
    />
  );
}

SiteRecommender.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(SiteRecommender);
