import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'core/libs/recompose';
import withTheme from 'core/hocs/withTheme';

import './index.styl';

const IMAGE_HEIGHT = 180;
const IMAGE_WIDTH = 300;

const NativeStyles = ({ theme }) => {
  return (
    <Fragment>
      <style global jsx>{`
        .native-ad-footer
          .ad_native_img
            width ${IMAGE_WIDTH}px
            height ${IMAGE_HEIGHT}px

          .ad_native_title
            font 700 26px/30px ${theme.fonts.nuance}
            color ${theme.colors.primary}
            transition color ${theme.animations.hover}

          .ad_native_desc
            font 18px/24px ${theme.fonts.nuance}
            color ${theme.colors.primary}

          .ad_native_title_wrapper
          .ad_native_desc
            margin-left ${IMAGE_WIDTH + 26}px

          .ad_label__text
            font 14px/18px ${theme.fonts.nuance}
            color ${theme.colors.hint}

          &:hover
            .ad_native_title
              color ${theme.colors.active1}
      `}</style>
    </Fragment>
  );
};

NativeStyles.propTypes = {
  theme: PropTypes.object,
};

export default function NativeFooterStyles(Component) {
  return compose(
    withTheme,
  )(props => (
    <div className='native-ad native-ad-footer'>
      <NativeStyles {...props} />
      <Component {...props} />
    </div>
  ));
}
