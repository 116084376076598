import PropTypes from 'prop-types';

import withBindProps from 'core/hocs/withBindProps';
import withBreakpoint from 'core/hocs/withBreakpoint';

import { Indent } from 'core/components/Wrappers';

import Mjolnir from 'core/components/Mjolnir';

import Card3 from 'site/cards/Card3';
import Card4 from 'site/cards/Card4';
import CardMain from 'site/cards/CardMain';

import { SIDE_INDENT_MOBILE, VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';


const Card4Type1 = withBindProps({ type: 1 })(Card4);
const Card4Type2 = withBindProps({ type: 2 })(Card4);
const Card3Type2 = withBindProps({ type: 2 })(Card3);
const CardMainType4 = withBindProps({ type: 4 })(CardMain);

function MainListPart2({ isDesktop, topics }) {
  /* eslint-disable react/prop-types */
  const HeroCard = isDesktop
    ? Card4Type2
    : ({ content }) => (
      <Indent left={-SIDE_INDENT_MOBILE} right={-SIDE_INDENT_MOBILE}>
        <Card4Type1 content={content} />
      </Indent>
    );
  /* eslint-enable react/prop-types */

  return (
    <Mjolnir
      content={topics}
      heroCard={HeroCard}
      card={isDesktop ? CardMainType4 : Card3Type2}
      interitemSpacing={isDesktop ? VERTICAL_INDENT : VERTICAL_INDENT_MOBILE}
      dataQa='main-list-second'
    />
  );
}

MainListPart2.propTypes = {
  isDesktop: PropTypes.bool,
  topics: PropTypes.array,
};

export default withBreakpoint(MainListPart2);
