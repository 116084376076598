import PropTypes from 'prop-types';
import { compose } from 'core/libs/recompose';

import withBindProps from 'core/hocs/withBindProps';

import Ad from 'core/components/Ad';
import AdBillboard from 'core/components/Ad/Billboard';

import sponsoredStyles from 'core/components/Ad/styles/sponsoredStyles';

import card3Type7Styles from './styles/card3Type7';
import Native1Styles from './styles/common/Native1';
import Native2Styles from './styles/common/Native2';
import NativeFooterStyles from './styles/NativeFooter';

import { RIGHT_COLUMN_WIDTH } from 'site/constants';

export const Billboard = withBindProps({
  name: 'Billboard',
  height: 250,
  blockId: '433125992',
  options: {
    p1: 'bscit',
    p2: 'y',
    pct: 'a',
  },
})(AdBillboard);

export const Fullscreen = withBindProps({
  name: 'Fullscreen',
  blockId: '440842170',
})(Ad);

export const Ad240x400 = withBindProps({
  name: '240x400',
  height: 600,
  width: RIGHT_COLUMN_WIDTH,
  stickyTemp: 3,
  blockId: '433125662',
  options: {
    p1: 'bsciw',
    p2: 'emhk',
    pct: 'a',
  },
})(Ad);

export const Ad240x400Second = withBindProps({
  name: '240x400_2nd',
  height: 600,
  width: RIGHT_COLUMN_WIDTH,
  lazy: true,
  blockId: '433125746',
  options: {
    p1: 'bsfwx',
    p2: 'ewqs',
    pct: 'a',
  },
})(Ad);

export const Ad240x200 = withBindProps({
  name: '240x200',
  width: RIGHT_COLUMN_WIDTH,
  lazy: true,
  blockId: '576320575',
})(Ad);

export const ContextAd = withBindProps({
  name: 'Context',
  height: 80,
  lazy: true,
  blockId: '434273834',
})(Ad);

export function Context({ count }) {
  return <ContextAd manualPuids={{ puid44: `context_item${count}` }} />;
}

Context.defaultProps = {
  count: 1,
};

Context.propTypes = {
  count: PropTypes.number,
};

export const Sponsored = compose(
  withBindProps({
    name: 'Sponsored',
    blockId: '576320935',
  }),
  sponsoredStyles,
)(Ad);

export const SuperFooter = withBindProps({
  name: '100%x240',
  height: 240,
  lazy: true,
  blockId: '433125368',
  options: {
    p1: 'bsciz',
    p2: 'fcuz',
    pct: 'b',
  },
})(Ad);

export const Parallax = withBindProps({
  name: 'Parallax',
  height: 250,
  lazy: true,
  blockId: '576321985',
})(Ad);

export const AdCenter = withBindProps({
  name: 'Center',
  height: 240,
  lazy: true,
  blockId: '576322017',
})(Ad);

export const InRead = withBindProps({
  name: 'inRead',
  height: 300,
  blockId: '433126038',
  options: {
    p1: 'bscjc',
    p2: 'fcvb',
    pct: 'a',
  },
})(Ad);

export const Native1AsCard2Type4 = compose(
  withBindProps({
    name: 'Native1',
    lazy: true,
    blockId: '433952612',
  }),
  Native1Styles,
)(Ad);

export const Native2AsCard2Type3 = compose(
  withBindProps({
    name: 'Native2',
    lazy: true,
    blockId: '433952652',
  }),
  Native2Styles,
)(Ad);

export const Native3 = compose(
  withBindProps({
    name: 'Native3',
    lazy: true,
    blockId: '576322033',
  }),
  card3Type7Styles,
)(Ad);

export const NativeFooter = compose(
  withBindProps({
    name: 'NativeFooter',
    lazy: true,
    blockId: '433952654',
  }),
  NativeFooterStyles,
)(Ad);

export const InGallery = withBindProps({
  name: 'InGallery',
  blockId: '576322049',
  disableAutoInit: true,
})(Ad);
