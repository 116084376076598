import PropTypes from 'prop-types';

import withBreakpoint from 'core/hocs/withBreakpoint';
import { Desktop, Mobile } from 'core/components/breakpoint';

import Feed from 'core/components/Feed';
import AdWrapper from 'core/components/Ad/AdWrapper';
import PageLoader from 'core/components/Loader/PageLoader';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import { Indent } from 'core/components/Wrappers';

import CardNews from 'site/cards/CardNews';

import {
  SuperFooter,
  Context,
} from 'site/components/Ads/desktop';

import {
  TopBanner,
  Listing1,
  ListingSpecSearch,
  Listing2,
} from 'site/components/Ads/mobile';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

function Content(props) {
  const {
    topics,
    isMobile,
    isLoading,
  } = props;

  if (isLoading) return <PageLoader />;
  if (!topics?.length) return null;

  const first = topics.slice(0, 2);
  const second = topics.slice(2, 5);
  const third = topics.slice(5, 7);
  const fourth = topics.slice(7, 10);
  const fifth = topics.slice(10, 12);
  const sixth = topics.slice(12, topics.length);

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  const feedProps = {
    card: CardNews,
    interitemSpacing: vertical,
  };

  return (
    <Indent bottom={vertical}>
      <AdWrapper top={vertical}>
        {first.length > 0 && (
          <Indent top={vertical}>
            <Feed
              content={first}
              {...feedProps}
            />
            <Mobile><TopBanner /></Mobile>
          </Indent>
        )}
        {second.length > 0 && (
          <Indent top={vertical}>
            <Feed
              content={second}
              {...feedProps}
            />
            <Desktop><SuperFooter /></Desktop>
            <Mobile><Listing1 /></Mobile>
          </Indent>
        )}
        {third.length > 0 && (
          <Indent top={vertical}>
            <Feed
              content={third}
              {...feedProps}
            />
          </Indent>
        )}
        {fourth.length > 0 && (
          <Indent top={vertical}>
            <Feed
              content={fourth}
              {...feedProps}
            />
            <Mobile><ListingSpecSearch /></Mobile>
          </Indent>
        )}
        {fifth.length > 0 && (
          <Indent top={vertical}>
            <Feed
              content={fifth}
              {...feedProps}
            />
            <Desktop><Context /></Desktop>
            <Mobile><Listing2 /></Mobile>
          </Indent>
        )}
        {sixth.length > 0 && (
          <Indent top={vertical}>
            <Feed
              content={sixth}
              {...feedProps}
            />
          </Indent>
        )}
      </AdWrapper>
    </Indent>
  );
}

Content.propTypes = {
  isMobile: PropTypes.bool,
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)).isRequired,
  isLoading: PropTypes.bool,
};

export default withBreakpoint(Content);
