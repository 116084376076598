import PropTypes from 'prop-types';
import cx from 'classnames';

import color from 'core/libs/color';
import withTheme from 'core/hocs/withTheme';
import withBreakpoint from 'core/hocs/withBreakpoint';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Image from 'core/components/Image';
import Link from 'core/components/Link';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import resolveRelationships from 'core/utils/relationships';

import {
  LAYOUT_MAX_WIDTH,
  LAYOUT_MAX_WIDTH_MOBILE,
  SIDE_INDENT_MOBILE,
} from 'site/constants';

import { Indent } from 'core/components/Wrappers';

import styles from './index.styl';

const relationships = resolveRelationships(
  ['image'],
  {},
  { image: { versions: {} } },
);

function TopicCoverImage({
  theme,
  breakpoint,
  isDesktop,
  content,
}) {
  const {
    attributes: {
      is_premium: isPremium,
      headline,
    },
  } = content;

  const {
    image: {
      caption,
      credits,
      credits_url: creditsUrl,
      versions,
    },
  } = relationships(content);

  const altAndTitle = caption || headline;
  const horisontal = isDesktop ? 0 : SIDE_INDENT_MOBILE;
  const maxWidth = isDesktop ? LAYOUT_MAX_WIDTH : LAYOUT_MAX_WIDTH_MOBILE;
  const withRoundCorner = !isPremium && isDesktop;

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
      .${styles.info}
        background ${isDesktop ? color(theme.colors.primary).alpha(0.8).string() : 'none'}

      .caption
      .credits
      .${styles.creditsUrl}
        font ${theme.texts.caption3.font}
        color ${theme.texts.caption3.color}
      `}</style>
    </scope>
  );

  return (
    <div className={cx(
      styles.coverImage,
      styles[breakpoint],
      withRoundCorner && styles.withRoundCorner,
    )}
    >
      <div className={styles.image}>
        <Image
          versions={versions}
          widthFromSrc
          maxWidth={maxWidth}
          maxHeight={600}
          placement='contain'
          useSchema
          alt={altAndTitle}
          title={altAndTitle}
        />
      </div>
      {(caption || credits) && (
        <Indent
          left={horisontal}
          right={horisontal}
        >
          <div className={scoped.wrapClassNames(styles.info)}>
            <div className={scoped.wrapClassNames('caption')}>{caption}</div>
            {credits && <div className={scoped.wrapClassNames('credits')}>
              {creditsUrl ? (
                <Link
                  className={scoped.wrapClassNames(styles.creditsUrl)}
                  to={creditsUrl}
                  type='blank'
                >
                  {credits}
                </Link>
              ) : credits }
            </div>}
          </div>
        </Indent>
      )}
      <scoped.styles />
    </div>
  );
}

TopicCoverImage.propTypes = {
  theme: PropTypes.object,
  content: PropTypes.object,
  isDesktop: PropTypes.bool,
  breakpoint: breakpointPropTypes(),
};

export default withTheme(withBreakpoint(TopicCoverImage));
