import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Mobile, Desktop } from 'core/components/breakpoint';
import withTheme from 'core/hocs/withTheme';

import styles from './index.styl';

function PartnerWrapper({ children, theme }) {
  return (
    <Fragment>
      <Mobile>
        {children}
      </Mobile>

      <Desktop>
        <div className={styles.partnerWrapper} data-qa='partner-wrapper'>
          <style jsx>{`
            .${styles.partnerWrapper}
              background ${theme.colors.content}
          `}</style>
          {children}
        </div>
      </Desktop>
    </Fragment>
  );
}

PartnerWrapper.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(PartnerWrapper);
